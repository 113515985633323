const FloatLabel = (() => {
  const activate = element => {
    element.classList.add("active");
  };
  const deactivate = element => {
    element.classList.remove("active");
  };

  const handleBlur = (input, element) => {
    if (!input.value) {
      deactivate(element);
    }
  };

  const bindEvents = element => {
    const input = element.querySelector("input");
    if (input.value || document.activeElement === input) {
      activate(element);
    }
    input.addEventListener("focus", () => activate(element));
    input.addEventListener("blur", () => handleBlur(input, element));
  };

  const init = () => {
    const floatContainers = document.querySelectorAll(".float-label");
    floatContainers.forEach(element => {
      bindEvents(element);
    });
  };
  return {
    init: init
  };
})();

setTimeout(() => {
  // Need to initialize after a timeout, otherwise input.value will read as blank even though it isn't
  FloatLabel.init();
}, 1);
