const RevealablePassword = () => {
  const svgNS = "http://www.w3.org/2000/svg";

  const state = {
    hide: true
  };

  const createSvg = () => {
    const svg = document.createElementNS(svgNS, "svg");
    svg.setAttributeNS(null, "viewBox", "0 0 32 22");
    svg.setAttributeNS(null, "fill", "none");
    svg.setAttributeNS(null, "fill-rule", "evenodd");
    svg.setAttributeNS(null, "stroke", "#64657E");
    svg.setAttributeNS(null, "stroke-linecap", "round");
    svg.setAttributeNS(null, "stroke-width", "2");
    return svg;
  };

  const addEye = svg => {
    const eyeOutline = document.createElementNS(svgNS, "path");
    eyeOutline.setAttributeNS(
      null,
      "d",
      "M30,11 C30,11 23.7304348,18 16,18 C8.26956522,18 2,11 2,11 C2,11 8.26956522,4 16,4 C23.7304348,4 30,11 30,11 Z"
    );
    svg.appendChild(eyeOutline);

    // <circle cx="16" cy="11" r="4"/>
    const iris = document.createElementNS(svgNS, "circle");
    iris.setAttributeNS(null, "cx", "16");
    iris.setAttributeNS(null, "cy", "11");
    iris.setAttributeNS(null, "r", "4");
    svg.appendChild(iris);
  };

  const addSlash = svg => {
    const slash = document.createElementNS(svgNS, "line");
    // <line x1="8" y1="21" x2="24" y2="1" id="Path"></line>
    slash.setAttributeNS(null, "x1", "8");
    slash.setAttributeNS(null, "y1", "21");
    slash.setAttributeNS(null, "x2", "24");
    slash.setAttributeNS(null, "y2", "1");

    svg.appendChild(slash);
  };

  const showIcon = (() => {
    const svg = createSvg();
    addEye(svg);
    return svg;
  })();

  const hideIcon = (() => {
    const svg = createSvg();
    addEye(svg);
    addSlash(svg);
    return svg;
  })();

  const toggleHandler = (toggle, input) => {
    if (!!state.hide) {
      toggle.removeChild(hideIcon);
      toggle.appendChild(showIcon);
      input.setAttribute("type", "text");
      input.focus();
      state.hide = false;
    } else {
      toggle.removeChild(showIcon);
      toggle.appendChild(hideIcon);
      input.setAttribute("type", "password");
      input.focus();
      state.hide = true;
    }
  };

  const bindEvents = (toggle, input) => {
    toggle.addEventListener("click", () => {
      toggleHandler(toggle, input);
    });
  };

  const init = revealableInput => {
    const input = revealableInput.querySelector("input[type=password]");
    const toggle = revealableInput.querySelector(".toggle");

    if (toggle) {
      bindEvents(toggle, input);
      toggle.appendChild(hideIcon);
    }
  };
  return {
    init: init
  };
};

const revealableInputs = document.querySelectorAll(".revealable-password");
Array.from(revealableInputs).forEach(wrapper => {
  RevealablePassword().init(wrapper);
});
